.main-header {
	position: fixed;
	top: 0;
	z-index: 9999;
	padding: 25px 20px 0 20px;
	width:100%;
	transition: $header-translations;
    height: 40px;
}

.logo {
	display: block;
	width: 300px;
	height: 117px;
	background: $bg-header;
	padding: 10px;
	float: left;
	transition: $header-translations;
	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.header-small {
	height: 40px;
	background: $bg-header;
	transition: $header-translations;
	padding-top: 0;
	.logo {
        width: 104px;
        height: 40px;
		padding: 3px;
		transition: $header-translations;
	}
	.menu-toggle {
		height: 40px;
		p {
			line-height: 39px;
		}
	}
}



//menu


.menu-toggle {
	width: 173px;
	height: 47px;
	padding: 0 15px;
	overflow: hidden;
	background: $bg-header;
	display: flex;
	justify-content: space-around;
	float: right;
	z-index: 9999;
    cursor: pointer;
	p {
		font-family: "AleoCust", serif;
		font-weight: 700;
		font-size: 3.4rem;
		color: $color-menu;
		text-transform: uppercase;
	}
}

//hamburger
.hamburger {
	position: relative;
	overflow: hidden;
	margin: 0;
	padding: 0;
	width: 27px;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	box-shadow: none;
	border-radius: 0;
	border: none;
}

.hamburger:focus {
	outline: none;
}

.hamburger span {
	display: block;
	position: absolute;
	top: calc(50% - 3px);
	left: 0;
	right: 0;
	height: 4px;
	background: white;

}

.hamburger span::before,
.hamburger span::after {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height: 4px;
	background-color: #fff;
	content: "";
}

.hamburger span::before {
	top: -10px;
}

.hamburger span::after {
	bottom: -10px;
}

.hamburger--x span::before,
.hamburger--x span::after {
	transition-duration: 0.3s, 0.3s;
	transition-delay: 0.3s, 0s;
}

.hamburger--x span::before {
	transition-property: top, transform;
}

.hamburger--x span::after {
	transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.hamburger--x.is-active span {
	background: transparent;

}

.hamburger--x.is-active span::before {
	top: 0;
	transform: rotate(45deg);
}

.hamburger--x.is-active span::after {
	bottom: 0;
	transform: rotate(-45deg);
}

.hamburger--x.is-active span::before,
.hamburger--x.is-active span::after {
	transition-delay: 0s, 0.3s;
}

.menu-mask {
	position: fixed;
	top: 0;
	left: -70vw;
	width: 70vw;
	height: 100%;
	opacity: 0.6;
	background: #000;
	z-index:5;
	transition: left 500ms;
	&.is-active{
		left: 0;
	}
}

.main-menu {
	position: fixed;
	top: 0;
	right: -30vw;
	width: 30vw;
	height:100%;
	background: $bg-header;
	//background: url("../img/menu_add.png") $bg-header no-repeat center bottom;
	z-index: 5;
	transition: right 500ms;
    padding: 85px 40px 0 0;
	&.is-active{
		right: 0;
	}
	li {
        text-align: right;
        margin-bottom: 5px;
        a {
            color: #fff;
            font-family: $second-font;
            font-weight: 300;
            font-size: 2.4rem;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media all and (max-width: 800px){
    .hs {
        height: 40px;
        background: $bg-header;
        transition: $header-translations;
        padding-top: 0;
        .logo {
            width: 104px;
            height: 40px;
            padding: 3px;
            transition: $header-translations;
        }
        .menu-toggle {
            height: 40px;
            p {
                line-height: 39px;
            }
        }
    }

    .menu-mask {
        width: 50vw;
    }

    .main-menu {
        width: 50vw;
        right: -50vw;
    }
}

@media all and (max-width: 500px){
    .menu-mask {
        width: 100vw;
        left: -100vw;

    }

    .main-menu {
        width: 100vw;
        right: -100vw;
    }
}