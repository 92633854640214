@keyframes bounce {
	from {
		transform: translateY(0px);
	}
	to {
		transform: translateY(-5px);
	}
}


.scroll-down {
	z-index: 3;
	position: absolute;
	bottom: 40px;
	color: #fff;
	text-decoration: none;
	left: calc(50% - 13px);
	font-size: 26px;
	animation: bounce 1s infinite alternate;
}

.section-nav {
	position: fixed;
	top: calc(50vh - 130px);
	right: 20px;
	z-index: 4;
	width: 60px;
	padding: 5px;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	li {
		margin: 5px 0;
	}
	a {
		text-decoration: none;
		color: #fff;
		font-size: 4.8rem;
		&:hover{
			color:$inViewColor;
		}
	}

}
@media all and (max-width: 800px){
	.scroll-down {
		bottom: 0;
	}
    .section-nav {
        width: auto;
        top: 50px;
        ul {
            display: flex;
            li {
                margin: 0px 10px;
            }
        }
        a {
            font-size: 3rem;
        }
    }
}

.inView {
	color: $inViewColor;
}