body.sub-page {
    overflow: hidden;
    .section {
        display: flex;
        .left, .right {
            flex: 50%;
            height: 100vh;
        }

        .right {
            padding:100px 50px 50px 50px;
            overflow: scroll;
        }

        @media all and (max-width: 1200px) {
            .right {
                padding:100px 25px 50px 25px;
            }
        }

        .left {
            background-size: cover;
            box-shadow: 0 0 100px 50px #ddd;
        }
    }

    h1, h2, h3 {
        font-family: $second-font;
        margin-bottom: 10px;
        font-weight: 700;
    }

    h1 {
        font-size: 3.6rem;
    }
    h2, h3 {
        font-size: 2.3rem;
    }

    p {
        margin-bottom: 10px;
    }

    table {
        border: 1px solid #32383e;
        font-size: 1.6rem;
        margin: 15px 0;
        thead {
            background: #32383e;
        }
        th {
            color: #fff;
            font-weight: bold;
            text-align: center;
            padding: 10px;
        }
        tr:nth-child(2n) {
            background: rgba(0, 0, 0, .05);
        }
        td {
            border: 0;
            padding: 5px 20px;
            font-weight: 700;
            & + td {
                font-weight: 400;
            }
        }

    }
    a.link {
        font-size: 1.6rem;
        color: $sec-color;
        font-weight: 700;
        &:hover {
            color: $bg-header;
            text-decoration: none;
        }
    }

    .deska-tarasowa {
        background: url('../img/deska_bg.jpg');
    }

    .kantowka {
        background: url('../img/kantowka_bg.jpg');
    }

    .podbitka {
        background: url('../img/podbitka_bg.jpg');
        background-position: bottom center;
    }
    .taras {
        background: url('../img/taras_bg.jpg');
        background-position: bottom center;
    }
    .zabezpieczenie {
        background: url('../img/zabezpieczenie_bg.jpg');
        background-position: center;
    }

    .contact-details {
        overflow: hidden;
        .icon-wrap{
            float: left;
            width: 20px;
            font-size: 18px;
            color: $bg-header;
        }
        p {
            float: left;
            padding-left: 5px;
        }
    }

}

@media all and (max-width: 800px) {
    body.sub-page {
        overflow: auto;
        .section {
            display: block;
            .left {
                height: 250px;
            }
            .right {
                overflow: inherit;
                padding: 20px 20px 50px 20px;
                height: auto;
            }
        }
    }
}


.mapp .leaflet-top .leaflet-left {
    left: 10px !important;
}
.leaflet-top {
    top: 158px !important;
}
