[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'Icon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-arrow-circle-down:before {
    content: "\e900";
}
.icon-pila2:before {
    content: "\e901";
}
.icon-house:before {
    content: "\e902";
}
.icon-log:before {
    content: "\e903";
}
.icon-phone:before {
    content: "\e904";
}
.icon-envelope-o:before {
    content: "\e905";
}
.icon-copyright:before {
    content: "\e906";
}
.icon-home:before {
    content: "\e907";
}
.icon-facebook:before {
    content: "\ea90";
}
.icon-facebook2:before {
    content: "\ea91";
}

