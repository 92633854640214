body.gallery {
    overflow: hidden;
    .section {
        padding-bottom:20px;
        display: flex;
        .left, .right {
            height: 100vh;
        }
        .left {
            flex:350px;
            padding: 180px 0 0 25px;
            box-shadow: inset -30px 0 80px 0px #ddd;

        }
        .right {
            flex: calc(100% - 350px);
            overflow: scroll;
            padding-bottom:30px;
        }

        h1, h2, h3 {
            font-family: $second-font;
            margin-bottom: 10px;
            font-weight: 700;
        }

        h1 {
            font-size: 3.6rem;
        }
        h2, h3 {
            font-size: 2.3rem;
        }
    }
}



@media all and (max-width: 800px) {
    body.gallery {
        overflow: auto;
        .section {
            display: block;
            .left {
                padding-top: 0;
                display: flex;
                align-items: flex-end;
                height: 106px;
            }
            .right {
                overflow: inherit;
                padding: 20px 20px 50px 20px;
                height: auto;
            }
        }
    }
}

div#masonry img {
    box-sizing: border-box;
    border: 2px solid #fff;
}