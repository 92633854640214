.headline {
    height: 200px;
    position: relative;
    .bottom-text {
        font-family: $bottom-text-font;
        color: #222222;
        font-size: 20rem;
        line-height: 1;
        opacity: .2;
        text-align: center;
    }
    .title {
        position: absolute;
        top: calc(50% - 25px);
        width: 100%;
        span {
            display: block;
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 5rem;
            line-height: 5rem;
        }
        hr {
            width: 80px;
            height: 6px;
            border: none;
        }
    }
}