//fonts

//roboto
@include fontFace('RobotoCust','../fonts/roboto/roboto-light', normal, 300);
@include fontFace('RobotoCust','../fonts/roboto/roboto-regular', normal, 400);
@include fontFace('RobotoCust','../fonts/roboto/roboto-bold', normal, 700);

//aleo
@include fontFace('AleoCust','../fonts/aleo/aleo-light', normal, 300);
@include fontFace('AleoCust','../fonts/aleo/aleo-regular', normal, 400);
@include fontFace('AleoCust','../fonts/aleo/aleo-bold', normal, 700);

//Tusj
@include fontFace('TusjCust','../fonts/tusj/tusj');

@include fontFace('Icon','../fonts/icon/iconfont');
