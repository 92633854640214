.produkty {
    .headline {
        background: #a7d96a;
    }
    .bottom-text {
        color: #006837;
    }
    .title {
        span {
            color: #fff;
        }
        hr {
            background: #000000;
        }
    }
}

.produkty-wrapper {
    height: calc(100% - 200px);

    .p-tabs-nav {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .p-tabs-nav__link {
        max-height: 220px;
        flex: 1;
        margin: 0 4px 0 0;
        padding: 12px;
        color: #000;
        background: #f1f1f1;
        text-align: center;
        transition: all 0.3s;
        cursor: pointer;
        border: 2px solid #f3f3f3;
        border-bottom: none;
        font-size: 2rem;
        font-weight: bold;
        @media screen and (max-width: 900px) {
            height: 70px;
        }
        &:last-child {
            margin: 0;
        }
        &:hover {
            fill: #6d6d6d;
        }
        &.is-active {
            color: #a7d96a;
            background: #fff;
        }
        i, span, .product-name {
            margin: 0;
            padding: 0;
            line-height: 1;
        }
        .product-name {
            display: none;
            @media screen and (min-width: 900px) {
                display: block;
            }
        }
        i {
            font-size: 1.8rem;
            @media screen and (min-width: 900px) {
                margin-bottom: 12px;
                font-size: 2.2rem;
            }
        }
    }

    .p-tab {
        display: none;
        background: #fff;
        border: 2px solid #f3f3f3;
        margin-top: -2px;
        padding: 20px 10px;
        &.is-active {
            display: block;
        }
    }
}

.imgCont {
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    @media screen and (max-width: 900px) {
        margin-bottom: 0;
    }
    img {
        width: 100%;
    }
}

.tab-content {
    overflow: hidden;
    .tab-img {
        border: 1px solid #ccc;
        padding: 5px;
        background: #f9f9f9;
        display: inline-block;
        line-height: 0;
        width: 25%;
        float: left;
        img {
            width: 100%;
        }
        @media screen and (max-width: 500px) {
            width: auto;
            margin: 0 auto;
            float: none;
            display: block;
        }

    }

    .tab-text {
        overflow: hidden;
        border: 1px solid #f3f3f3;
        background: #f9f9f9;
        padding: 10px;
        margin-bottom: 20px;
        .tab-title {
            font-size: 2rem;
            font-weight: bold;
            color: $bg-header;
            margin-bottom: 5px;
        }

        .read-more {
            background: #fff;
            margin-top: 10px;
            float: right;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            border: 1px solid $sec-color;
            padding: 7px 15px;
            color: $sec-color;
            font-weight: normal;
            transition: all 0.5s ease;
            &:hover {
                border: 1px solid $bg-header;
                background: $bg-header;
                color: #fff;
                transition: all 0.5s ease;
            }
            @media screen and (max-width: 500px) {
                float: none;
                display: block;
            }
        }
    }
}

.tab-image {
    text-decoration: none;
    img {
        max-width: 160px;
        width: 100%;
        border: 1px solid #f3f3f3;
        background: #f9f9f9;
        display: inline-block;
        margin: 4px 4px 4px 0px;
        padding: 5px;
        position: relative;
        transition: all .2s ease-in-out;
        &:hover {
            background-color: $sec-color;
        }
    }
}



@media all and  (max-width: 800px) {
    .tab-images {
        display: flex;
        flex-wrap: wrap;
        .tab-image {
            width: 30%;
            margin: 0 1%;
        }
    }
}