$main-font: 'RobotoCust', sans-serif;
$second-font: 'AleoCust', serif;
$bottom-text-font: 'TusjCust';
$bg-header: #006837;
$color-menu: #fff;
$font-color: #333333;
$header-translations: all 0.2s ease-out;

$inViewColor: #f1c491;
$sec-color: #a7d96a;

html {
	font-size: 62.5%;
}

body {
	font-family: $main-font;
	color: $font-color;
	font-size: 1.4rem;
	line-height: 1.3;
	&.hidden {
		overflow: hidden;
	}
}

iframe {
	border: none;
}

.mp-section {
	width: 100%;
	height: 100vh;
	position: relative;
}

.content {
	width: 1170px;
	margin: 30px auto 0;
}

p {
	font-size: 1.6rem;
	font-weight: 300;
}


@media all and (max-width: 1169px) {
    .content {
        width: 90%;
    }
}

@media all and (max-width: 800px) {
    .mp-section{
        height: auto;
    }
}