@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, figcaption, figure {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

body {
  line-height: 1;
  color: black;
}

ol, ul {
  list-style: none;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
}

blockquote, q {
  quotes: "" "";
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body.hidden-scroll {
  overflow: hidden;
}

.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  opacity: 0.85;
  display: none;
  z-index: 10500;
}

.sl-wrapper {
  z-index: 10400;
}
.sl-wrapper button {
  border: 0 none;
  background: transparent;
  font-size: 28px;
  padding: 0;
  cursor: pointer;
}
.sl-wrapper button:hover {
  opacity: 0.7;
}
.sl-wrapper .sl-close {
  display: none;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 10600;
  margin-top: -14px;
  margin-right: -14px;
  height: 44px;
  width: 44px;
  line-height: 44px;
  font-family: "RobotoCust", sans-serif;
  color: #fff;
  font-size: 3rem;
}
.sl-wrapper .sl-close:focus {
  outline: none;
}
.sl-wrapper .sl-counter {
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 10600;
  color: #fff;
  font-size: 1rem;
}
.sl-wrapper .sl-navigation {
  width: 100%;
  display: none;
}
.sl-wrapper .sl-navigation button {
  position: fixed;
  top: 50%;
  margin-top: -22px;
  height: 44px;
  width: 22px;
  line-height: 44px;
  text-align: center;
  display: block;
  z-index: 10600;
  font-family: "RobotoCust", sans-serif;
  color: #fff;
}
.sl-wrapper .sl-navigation button.sl-next {
  right: 5px;
  font-size: 2rem;
}
.sl-wrapper .sl-navigation button.sl-prev {
  left: 5px;
  font-size: 2rem;
}
.sl-wrapper .sl-navigation button:focus {
  outline: none;
}
@media (min-width: 35.5em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }
  .sl-wrapper .sl-navigation button.sl-next {
    right: 10px;
    font-size: 3rem;
  }
  .sl-wrapper .sl-navigation button.sl-prev {
    left: 10px;
    font-size: 3rem;
  }
}
@media (min-width: 50em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }
  .sl-wrapper .sl-navigation button.sl-next {
    right: 20px;
    font-size: 3rem;
  }
  .sl-wrapper .sl-navigation button.sl-prev {
    left: 20px;
    font-size: 3rem;
  }
}
.sl-wrapper .sl-image {
  position: fixed;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 100000;
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 0px #fff;
}
.sl-wrapper .sl-image img {
  margin: 0;
  padding: 0;
  display: block;
  border: 0 none;
}
@media (min-width: 35.5em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}
@media (min-width: 50em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}
.sl-wrapper .sl-image iframe {
  background: #000;
  border: 0 none;
}
@media (min-width: 35.5em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}
@media (min-width: 50em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}
.sl-wrapper .sl-image .sl-caption {
  display: none;
  padding: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.sl-wrapper .sl-image .sl-caption.pos-top {
  bottom: auto;
  top: 0;
}
.sl-wrapper .sl-image .sl-caption.pos-outside {
  bottom: auto;
}
.sl-wrapper .sl-image .sl-download {
  display: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #fff;
  z-index: 10600;
}

.sl-spinner {
  display: none;
  border: 5px solid #333;
  border-radius: 40px;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  position: fixed;
  top: 50%;
  width: 40px;
  z-index: 1007;
  -webkit-animation: pulsate 1s ease-out infinite;
  -moz-animation: pulsate 1s ease-out infinite;
  -ms-animation: pulsate 1s ease-out infinite;
  -o-animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
}

.sl-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-moz-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-o-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@-ms-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@font-face {
  font-family: "RobotoCust";
  src: url("../fonts/roboto/roboto-light.eot");
  src: url("../fonts/roboto/roboto-light.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/roboto-light.woff") format("woff"), url("../fonts/roboto/roboto-light.ttf") format("truetype"), url("../fonts/roboto/roboto-light.svg#RobotoCust") format("svg");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "RobotoCust";
  src: url("../fonts/roboto/roboto-regular.eot");
  src: url("../fonts/roboto/roboto-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/roboto-regular.woff") format("woff"), url("../fonts/roboto/roboto-regular.ttf") format("truetype"), url("../fonts/roboto/roboto-regular.svg#RobotoCust") format("svg");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "RobotoCust";
  src: url("../fonts/roboto/roboto-bold.eot");
  src: url("../fonts/roboto/roboto-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/roboto-bold.woff") format("woff"), url("../fonts/roboto/roboto-bold.ttf") format("truetype"), url("../fonts/roboto/roboto-bold.svg#RobotoCust") format("svg");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "AleoCust";
  src: url("../fonts/aleo/aleo-light.eot");
  src: url("../fonts/aleo/aleo-light.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo/aleo-light.woff") format("woff"), url("../fonts/aleo/aleo-light.ttf") format("truetype"), url("../fonts/aleo/aleo-light.svg#AleoCust") format("svg");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "AleoCust";
  src: url("../fonts/aleo/aleo-regular.eot");
  src: url("../fonts/aleo/aleo-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo/aleo-regular.woff") format("woff"), url("../fonts/aleo/aleo-regular.ttf") format("truetype"), url("../fonts/aleo/aleo-regular.svg#AleoCust") format("svg");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "AleoCust";
  src: url("../fonts/aleo/aleo-bold.eot");
  src: url("../fonts/aleo/aleo-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo/aleo-bold.woff") format("woff"), url("../fonts/aleo/aleo-bold.ttf") format("truetype"), url("../fonts/aleo/aleo-bold.svg#AleoCust") format("svg");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "TusjCust";
  src: url("../fonts/tusj/tusj.eot");
  src: url("../fonts/tusj/tusj.eot?#iefix") format("embedded-opentype"), url("../fonts/tusj/tusj.woff") format("woff"), url("../fonts/tusj/tusj.ttf") format("truetype"), url("../fonts/tusj/tusj.svg#TusjCust") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Icon";
  src: url("../fonts/icon/iconfont.eot");
  src: url("../fonts/icon/iconfont.eot?#iefix") format("embedded-opentype"), url("../fonts/icon/iconfont.woff") format("woff"), url("../fonts/icon/iconfont.ttf") format("truetype"), url("../fonts/icon/iconfont.svg#Icon") format("svg");
  font-style: normal;
  font-weight: normal;
}
html {
  font-size: 62.5%;
}

body {
  font-family: "RobotoCust", sans-serif;
  color: #333333;
  font-size: 1.4rem;
  line-height: 1.3;
}
body.hidden {
  overflow: hidden;
}

iframe {
  border: none;
}

.mp-section {
  width: 100%;
  height: 100vh;
  position: relative;
}

.content {
  width: 1170px;
  margin: 30px auto 0;
}

p {
  font-size: 1.6rem;
  font-weight: 300;
}

@media all and (max-width: 1169px) {
  .content {
    width: 90%;
  }
}
@media all and (max-width: 800px) {
  .mp-section {
    height: auto;
  }
}
.main-header {
  position: fixed;
  top: 0;
  z-index: 9999;
  padding: 25px 20px 0 20px;
  width: 100%;
  transition: all 0.2s ease-out;
  height: 40px;
}

.logo {
  display: block;
  width: 300px;
  height: 117px;
  background: #006837;
  padding: 10px;
  float: left;
  transition: all 0.2s ease-out;
}
.logo img {
  max-width: 100%;
  max-height: 100%;
}

.header-small {
  height: 40px;
  background: #006837;
  transition: all 0.2s ease-out;
  padding-top: 0;
}
.header-small .logo {
  width: 104px;
  height: 40px;
  padding: 3px;
  transition: all 0.2s ease-out;
}
.header-small .menu-toggle {
  height: 40px;
}
.header-small .menu-toggle p {
  line-height: 39px;
}

.menu-toggle {
  width: 173px;
  height: 47px;
  padding: 0 15px;
  overflow: hidden;
  background: #006837;
  display: flex;
  justify-content: space-around;
  float: right;
  z-index: 9999;
  cursor: pointer;
}
.menu-toggle p {
  font-family: "AleoCust", serif;
  font-weight: 700;
  font-size: 3.4rem;
  color: #fff;
  text-transform: uppercase;
}

.hamburger {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 27px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
}

.hamburger:focus {
  outline: none;
}

.hamburger span {
  display: block;
  position: absolute;
  top: calc(50% - 3px);
  left: 0;
  right: 0;
  height: 4px;
  background: white;
}

.hamburger span::before,
.hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #fff;
  content: "";
}

.hamburger span::before {
  top: -10px;
}

.hamburger span::after {
  bottom: -10px;
}

.hamburger--x span::before,
.hamburger--x span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
}

.hamburger--x span::before {
  transition-property: top, transform;
}

.hamburger--x span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.hamburger--x.is-active span {
  background: transparent;
}

.hamburger--x.is-active span::before {
  top: 0;
  transform: rotate(45deg);
}

.hamburger--x.is-active span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.hamburger--x.is-active span::before,
.hamburger--x.is-active span::after {
  transition-delay: 0s, 0.3s;
}

.menu-mask {
  position: fixed;
  top: 0;
  left: -70vw;
  width: 70vw;
  height: 100%;
  opacity: 0.6;
  background: #000;
  z-index: 5;
  transition: left 500ms;
}
.menu-mask.is-active {
  left: 0;
}

.main-menu {
  position: fixed;
  top: 0;
  right: -30vw;
  width: 30vw;
  height: 100%;
  background: #006837;
  z-index: 5;
  transition: right 500ms;
  padding: 85px 40px 0 0;
}
.main-menu.is-active {
  right: 0;
}
.main-menu li {
  text-align: right;
  margin-bottom: 5px;
}
.main-menu li a {
  color: #fff;
  font-family: "AleoCust", serif;
  font-weight: 300;
  font-size: 2.4rem;
  text-decoration: none;
}
.main-menu li a:hover {
  text-decoration: underline;
}

@media all and (max-width: 800px) {
  .hs {
    height: 40px;
    background: #006837;
    transition: all 0.2s ease-out;
    padding-top: 0;
  }
  .hs .logo {
    width: 104px;
    height: 40px;
    padding: 3px;
    transition: all 0.2s ease-out;
  }
  .hs .menu-toggle {
    height: 40px;
  }
  .hs .menu-toggle p {
    line-height: 39px;
  }

  .menu-mask {
    width: 50vw;
  }

  .main-menu {
    width: 50vw;
    right: -50vw;
  }
}
@media all and (max-width: 500px) {
  .menu-mask {
    width: 100vw;
    left: -100vw;
  }

  .main-menu {
    width: 100vw;
    right: -100vw;
  }
}
.slider-wrapper {
  position: relative;
}

.slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  transition: opacity 1s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.showing {
  opacity: 1;
  z-index: 2;
}

#pagination {
  position: absolute;
  top: calc(50vh - 127px);
  left: 2vw;
  z-index: 4;
}
#pagination li {
  padding: 3px 0;
}
#pagination li span {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #ffffff;
  box-shadow: 0 0 14px 1px #000, 0 0 14px 1px #000 inset;
  background-size: cover;
}
#pagination li span.big {
  display: block;
  width: 80px;
  height: 80px;
  border: 2px solid #ffffff;
}

.slogan {
  position: absolute;
  left: 15vw;
  top: 40vh;
  width: 70%;
  padding: 25px;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-weight: 300;
}
.slogan h1, .slogan h2 {
  font-family: "AleoCust", serif;
  font-size: 3.6rem;
}
.slogan p {
  font-size: 1.6rem;
}

@media all and (max-width: 800px) {
  .slider-wrapper {
    height: calc(100vh - 70px);
  }

  .slogan {
    left: 2vw;
    width: 96vw;
    top: 30vh;
    padding: 15px;
  }

  #pagination {
    position: absolute;
    top: calc(100% - 80px);
    left: 2vw;
    z-index: 4;
  }
  #pagination li {
    display: inline-block;
    padding: 3px 0;
    margin-right: 5px;
  }
  #pagination li span {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-size: 30px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    box-shadow: 0 0 14px 1px #000, 0 0 14px 1px #000 inset;
  }
  #pagination li span.big {
    display: block;
    width: 40px;
    height: 40px;
    background-size: 40px;
    border: 1px solid #ffffff;
  }
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-circle-down:before {
  content: "";
}

.icon-pila2:before {
  content: "";
}

.icon-house:before {
  content: "";
}

.icon-log:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-envelope-o:before {
  content: "";
}

.icon-copyright:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-facebook2:before {
  content: "";
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-5px);
  }
}
.scroll-down {
  z-index: 3;
  position: absolute;
  bottom: 40px;
  color: #fff;
  text-decoration: none;
  left: calc(50% - 13px);
  font-size: 26px;
  animation: bounce 1s infinite alternate;
}

.section-nav {
  position: fixed;
  top: calc(50vh - 130px);
  right: 20px;
  z-index: 4;
  width: 60px;
  padding: 5px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.section-nav li {
  margin: 5px 0;
}
.section-nav a {
  text-decoration: none;
  color: #fff;
  font-size: 4.8rem;
}
.section-nav a:hover {
  color: #f1c491;
}

@media all and (max-width: 800px) {
  .scroll-down {
    bottom: 0;
  }

  .section-nav {
    width: auto;
    top: 50px;
  }
  .section-nav ul {
    display: flex;
  }
  .section-nav ul li {
    margin: 0px 10px;
  }
  .section-nav a {
    font-size: 3rem;
  }
}
.inView {
  color: #f1c491;
}

footer {
  display: inline-flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  background: #006837;
  font-family: "RobotoCust", sans-serif;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 30px;
  z-index: 6;
}
footer ul li {
  display: inline-block;
  padding-right: 10px;
}
footer ul li span {
  padding-right: 5px;
}
footer a {
  color: #fff;
  text-decoration: none;
}

.headline {
  height: 200px;
  position: relative;
}
.headline .bottom-text {
  font-family: "TusjCust";
  color: #222222;
  font-size: 20rem;
  line-height: 1;
  opacity: 0.2;
  text-align: center;
}
.headline .title {
  position: absolute;
  top: calc(50% - 25px);
  width: 100%;
}
.headline .title span {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 5rem;
  line-height: 5rem;
}
.headline .title hr {
  width: 80px;
  height: 6px;
  border: none;
}

.o-firmie {
  background: url("../img/produkty_bg.jpg") no-repeat;
  background-size: cover;
}
.o-firmie .bottom-text {
  color: #222222;
}
.o-firmie .title span {
  color: #222222;
}
.o-firmie .title hr {
  background: #006837;
}
.o-firmie p {
  text-align: center;
  padding-bottom: 20px;
}
.o-firmie .icon-arrow-circle-down {
  color: #333;
}

.produkty .headline {
  background: #a7d96a;
}
.produkty .bottom-text {
  color: #006837;
}
.produkty .title span {
  color: #fff;
}
.produkty .title hr {
  background: #000000;
}

.produkty-wrapper {
  height: calc(100% - 200px);
}
.produkty-wrapper .p-tabs-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.produkty-wrapper .p-tabs-nav__link {
  max-height: 220px;
  flex: 1;
  margin: 0 4px 0 0;
  padding: 12px;
  color: #000;
  background: #f1f1f1;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  border: 2px solid #f3f3f3;
  border-bottom: none;
  font-size: 2rem;
  font-weight: bold;
}
@media screen and (max-width: 900px) {
  .produkty-wrapper .p-tabs-nav__link {
    height: 70px;
  }
}
.produkty-wrapper .p-tabs-nav__link:last-child {
  margin: 0;
}
.produkty-wrapper .p-tabs-nav__link:hover {
  fill: #6d6d6d;
}
.produkty-wrapper .p-tabs-nav__link.is-active {
  color: #a7d96a;
  background: #fff;
}
.produkty-wrapper .p-tabs-nav__link i, .produkty-wrapper .p-tabs-nav__link span, .produkty-wrapper .p-tabs-nav__link .product-name {
  margin: 0;
  padding: 0;
  line-height: 1;
}
.produkty-wrapper .p-tabs-nav__link .product-name {
  display: none;
}
@media screen and (min-width: 900px) {
  .produkty-wrapper .p-tabs-nav__link .product-name {
    display: block;
  }
}
.produkty-wrapper .p-tabs-nav__link i {
  font-size: 1.8rem;
}
@media screen and (min-width: 900px) {
  .produkty-wrapper .p-tabs-nav__link i {
    margin-bottom: 12px;
    font-size: 2.2rem;
  }
}
.produkty-wrapper .p-tab {
  display: none;
  background: #fff;
  border: 2px solid #f3f3f3;
  margin-top: -2px;
  padding: 20px 10px;
}
.produkty-wrapper .p-tab.is-active {
  display: block;
}

.imgCont {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
@media screen and (max-width: 900px) {
  .imgCont {
    margin-bottom: 0;
  }
}
.imgCont img {
  width: 100%;
}

.tab-content {
  overflow: hidden;
}
.tab-content .tab-img {
  border: 1px solid #ccc;
  padding: 5px;
  background: #f9f9f9;
  display: inline-block;
  line-height: 0;
  width: 25%;
  float: left;
}
.tab-content .tab-img img {
  width: 100%;
}
@media screen and (max-width: 500px) {
  .tab-content .tab-img {
    width: auto;
    margin: 0 auto;
    float: none;
    display: block;
  }
}
.tab-content .tab-text {
  overflow: hidden;
  border: 1px solid #f3f3f3;
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 20px;
}
.tab-content .tab-text .tab-title {
  font-size: 2rem;
  font-weight: bold;
  color: #006837;
  margin-bottom: 5px;
}
.tab-content .tab-text .read-more {
  background: #fff;
  margin-top: 10px;
  float: right;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: 1px solid #a7d96a;
  padding: 7px 15px;
  color: #a7d96a;
  font-weight: normal;
  transition: all 0.5s ease;
}
.tab-content .tab-text .read-more:hover {
  border: 1px solid #006837;
  background: #006837;
  color: #fff;
  transition: all 0.5s ease;
}
@media screen and (max-width: 500px) {
  .tab-content .tab-text .read-more {
    float: none;
    display: block;
  }
}

.tab-image {
  text-decoration: none;
}
.tab-image img {
  max-width: 160px;
  width: 100%;
  border: 1px solid #f3f3f3;
  background: #f9f9f9;
  display: inline-block;
  margin: 4px 4px 4px 0px;
  padding: 5px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.tab-image img:hover {
  background-color: #a7d96a;
}

@media all and (max-width: 800px) {
  .tab-images {
    display: flex;
    flex-wrap: wrap;
  }
  .tab-images .tab-image {
    width: 30%;
    margin: 0 1%;
  }
}
body.sub-page {
  overflow: hidden;
}
body.sub-page .section {
  display: flex;
}
body.sub-page .section .left, body.sub-page .section .right {
  flex: 50%;
  height: 100vh;
}
body.sub-page .section .right {
  padding: 100px 50px 50px 50px;
  overflow: scroll;
}
@media all and (max-width: 1200px) {
  body.sub-page .section .right {
    padding: 100px 25px 50px 25px;
  }
}
body.sub-page .section .left {
  background-size: cover;
  box-shadow: 0 0 100px 50px #ddd;
}
body.sub-page h1, body.sub-page h2, body.sub-page h3 {
  font-family: "AleoCust", serif;
  margin-bottom: 10px;
  font-weight: 700;
}
body.sub-page h1 {
  font-size: 3.6rem;
}
body.sub-page h2, body.sub-page h3 {
  font-size: 2.3rem;
}
body.sub-page p {
  margin-bottom: 10px;
}
body.sub-page table {
  border: 1px solid #32383e;
  font-size: 1.6rem;
  margin: 15px 0;
}
body.sub-page table thead {
  background: #32383e;
}
body.sub-page table th {
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}
body.sub-page table tr:nth-child(2n) {
  background: rgba(0, 0, 0, 0.05);
}
body.sub-page table td {
  border: 0;
  padding: 5px 20px;
  font-weight: 700;
}
body.sub-page table td + td {
  font-weight: 400;
}
body.sub-page a.link {
  font-size: 1.6rem;
  color: #a7d96a;
  font-weight: 700;
}
body.sub-page a.link:hover {
  color: #006837;
  text-decoration: none;
}
body.sub-page .deska-tarasowa {
  background: url("../img/deska_bg.jpg");
}
body.sub-page .kantowka {
  background: url("../img/kantowka_bg.jpg");
}
body.sub-page .podbitka {
  background: url("../img/podbitka_bg.jpg");
  background-position: bottom center;
}
body.sub-page .taras {
  background: url("../img/taras_bg.jpg");
  background-position: bottom center;
}
body.sub-page .zabezpieczenie {
  background: url("../img/zabezpieczenie_bg.jpg");
  background-position: center;
}
body.sub-page .contact-details {
  overflow: hidden;
}
body.sub-page .contact-details .icon-wrap {
  float: left;
  width: 20px;
  font-size: 18px;
  color: #006837;
}
body.sub-page .contact-details p {
  float: left;
  padding-left: 5px;
}

@media all and (max-width: 800px) {
  body.sub-page {
    overflow: auto;
  }
  body.sub-page .section {
    display: block;
  }
  body.sub-page .section .left {
    height: 250px;
  }
  body.sub-page .section .right {
    overflow: inherit;
    padding: 20px 20px 50px 20px;
    height: auto;
  }
}
.mapp .leaflet-top .leaflet-left {
  left: 10px !important;
}

.leaflet-top {
  top: 158px !important;
}

body.gallery {
  overflow: hidden;
}
body.gallery .section {
  padding-bottom: 20px;
  display: flex;
}
body.gallery .section .left, body.gallery .section .right {
  height: 100vh;
}
body.gallery .section .left {
  flex: 350px;
  padding: 180px 0 0 25px;
  box-shadow: inset -30px 0 80px 0px #ddd;
}
body.gallery .section .right {
  flex: calc(100% - 350px);
  overflow: scroll;
  padding-bottom: 30px;
}
body.gallery .section h1, body.gallery .section h2, body.gallery .section h3 {
  font-family: "AleoCust", serif;
  margin-bottom: 10px;
  font-weight: 700;
}
body.gallery .section h1 {
  font-size: 3.6rem;
}
body.gallery .section h2, body.gallery .section h3 {
  font-size: 2.3rem;
}

@media all and (max-width: 800px) {
  body.gallery {
    overflow: auto;
  }
  body.gallery .section {
    display: block;
  }
  body.gallery .section .left {
    padding-top: 0;
    display: flex;
    align-items: flex-end;
    height: 106px;
  }
  body.gallery .section .right {
    overflow: inherit;
    padding: 20px 20px 50px 20px;
    height: auto;
  }
}
div#masonry img {
  box-sizing: border-box;
  border: 2px solid #fff;
}