.o-firmie {
    background: url('../img/produkty_bg.jpg') no-repeat;
    background-size: cover;
    .bottom-text {
        color: #222222;
    }
    .title {
        span {
            color: #222222;
        }
        hr {
            background: #006837;
        }
    }
    p {
        text-align: center;
        padding-bottom: 20px;
    }
    .icon-arrow-circle-down {
        color: #333;
    }
}
