
.slider-wrapper {
	position: relative;
}

.slide {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 1;
	transition: opacity 1s;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom left;
}

.showing {
	opacity: 1;
	z-index: 2;
}

#pagination {
	position: absolute;
	top: calc(50vh - 127px);
	left: 2vw;
	z-index: 4;
	li {
		padding: 3px 0;
		span {
			display: block;
			width: 50px;
			height: 50px;
			margin: 0 auto;
			border-radius: 50%;
			border: 1px solid #ffffff;
			box-shadow: 0 0 14px 1px #000, 0 0 14px 1px #000 inset;
			background-size: cover;
			&.big {
				display: block;
				width: 80px;
				height: 80px;
				border: 2px solid #ffffff;
			}
		}
	}
}

.slogan {
	position: absolute;
	left: 15vw;
	top: 40vh;
	width: 70%;
	padding: 25px;
	background: rgba(0, 0, 0, 0.6);
	color: #ffffff;
	font-weight: 300;
	h1, h2 {
		font-family: $second-font;
		font-size: 3.6rem;
	}
	p {
		font-size: 1.6rem;
	}

}


@media all and (max-width: 800px) {
    .slider-wrapper {
        height: calc(100vh - 70px);
    }

    .slogan {
        left: 2vw;
		width: 96vw;
        top: 30vh;
        padding: 15px;
    }


    #pagination {
        position: absolute;
        top: calc(100% - 80px);
        left: 2vw;
        z-index: 4;
        li {
            display: inline-block;
            padding: 3px 0;
            margin-right: 5px;
            span {
                display: block;
                width: 30px;
                height: 30px;
                margin: 0 auto;
                background-size: 30px;
                border-radius: 50%;
                border: 1px solid #ffffff;
                box-shadow: 0 0 14px 1px #000, 0 0 14px 1px #000 inset ;
                &.big {
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-size: 40px;
                    border: 1px solid #ffffff;

                }
            }
        }
    }
}




