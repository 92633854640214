footer {
    display: inline-flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    background: $bg-header;
    font-family: $main-font;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 30px;
    z-index: 6;
    ul li {
        display: inline-block;
        padding-right: 10px;
        span {
            padding-right: 5px;
        }
    }
    a {
        color: #fff;
        text-decoration: none;
    }
}